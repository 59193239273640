export const ru = {
  lang_title: 'RU',
  message: {
    logged: 'Вы вошли в систему!',
    logout: 'Вы вышли из системы!',
    password_reset_link_sent_to_email: 'Ссылка на сброс пароля отправлена на почту',
    changed_lang: 'Язык сменен на русский',
    minTestLength: 'Минимальное количество ответов два',
    zero_resume: 'Для отклика вам нужно создать резюме',
    your_email_has_been_successfully_verified: 'Ваш email успешно подтвержден!',
    your_password_has_been_successfully_changed: 'Ваш пароль успешно изменен!',
    request_failed: 'Ошибка запроса! Пожалуйста проверьте подключены ли вы к интернету',
    server_error: 'Ошибка сервера!',
    you_first_need_to_login: 'Вам сначало нужно войти!',
    we_do_not_have_such_a_page: 'У нас нет такой страницы!',
    you_do_not_have_access_to_this_page: 'У вас нет доступа к этой странице!',
    an_error_occurred_while_requesting: 'Возникла ошибка при запросе! Пожалуйста повторите попытку позднее.',
    looks_like_it_s_empty: 'Похоже тут пусто!',
    you_need_to_log_in_as_a_user: 'Вам нужно авторизоваться как соискатель!',
    pay_the_tariff_for_viewing_contacts: 'Оплатите тариф для просмотра контактов!',
    first_but_a_package_to_create_a_manager: 'Сначала купите пакет для создания менеджера!',
    first_but_a_package_to_create_a_vacancy: 'Сначала купите пакет для создания вакансии!',
    to_respond_you_need_to_pass_a_test: 'Для отклика нужно пройти тест.',
    no_top_resume_service: 'Сначала купите пакет для поднятия резюме в топ!',
    access_resume_to_top_list: 'Ваше резюме было успешно добавлено в топ!'

  },
  us_address_title: 'Наш адрес',
  skype: 'Скайп',
  search: 'Поиск',
  logout: 'Выйти',
  filter: 'Фильтр',
  link_to_file: 'Ссылка на файл',

  // Address
  address: 'Респубика Казахстан, город Алматы, пр. Достык 210, БЦ "Коктем Гранд", 2-блок, 2-этаж, офис 28',
  we_are_in_social_networks: 'Мы в социальных сетях',
  copyright: '© 2022 Altyn Adam. Все права защищены',
  your_city: 'Ваш город',

  // Upload Image
  photo_cropping: 'Кадрирование фото',
  select_photo: 'Выбрать фото',
  save_as_profile_photo: 'Сохранить как фото профиля',
  profile_photo: 'Фото профиля',
  change_your_profile_photo: 'Изменение фото профиля',
  drag_photo_here: 'Перетащите фото сюда.',
  download_from_device: 'Загрузить с устройсва',
  or: 'или',
  by_profile_photo: 'По фото профиля другие люди смогут вас узнавать, а вам будет проще определять, в какой аккаунт вы вошли.',

  // ============================= Home Pages ==============================
  // Intro
  intro: {
    title: 'HR консалтинговое и рекрутинговое агентство',
    subtitle: 'Работа во всех регионах Казахстана, странах СНГ и зарубежом'
  },

  // Salse
  sales: 'Акции',
  LastMinuteJobs: 'Горящие вакансии',

  // About
  aboutUs: {
    title: 'О компании',
    text: {
      text1: 'C 2005 года Altyn Adam Agency осуществляет полный спектр услуг в области управления персоналом: поиска и подбора, обучения и развития, аутсорсинга и аутстаффинга персонала. За этот период времени наша компания расширилась, приобрела хорошую репутацию на рынке Казахстана и за рубежом за качество услуг, высокую результативность и знание своей отрасли.',
      text2: 'База данных соискателей Altyn Adam Agency включает резюме соискателей всех отраслей деятельности: нефть и газ, банковское дело, теплоэнергетика, уранодобывающая промышленность, финансовый сектор, промышленность, инжиниринг, FMCG, юриспруденция, строительство и недвижимость, производственный и рабочий персонал, сельское хозяйство, транспорт и логистика и др.',
      text3: 'Резюме соискателей в базе создаются по специальной схеме, которая учитывает все данные для составления грамотного, подробного, корректного резюме, включая полную информацию, необходимую работодателю для принятия решения относительно кандидата.',
      text4: 'Каждая Компания, будучи клиентом нашего Агентства, имеет возможность выгодного сотрудничества в области поиска и подбора персонала, аутсорсинга и аутстаффинга, обучения и развития персонала.',
      text5: 'Наша цель – создать лучшие условия для наших клиентов и соискателей при поиске и подборе квалифицированного персонала и поиске лучшего места работы.',
      text6: 'С уважением,Команда ТОО «Altyn Adam Agency»'
    }
  },

  // Services
  services: {
    title: 'Услуги',
    button: 'Подробнее',
    connected: 'Подключенные услуги'
  },
  services_tipes: 'Виды услуг по рекрутингу персонала:',
  pay: 'Оплатить',
  by_SMS: 'по SMS',

  // About us - История
  home: 'Главная',
  contacts: 'Контакты',
  history: 'История',
  company_development_stages: 'Важные этапы развития компании',
  team: 'Команда',
  partners: 'Наши партнеры',
  principles: 'Основные принципы',
  certificates: 'Награды, сертификаты, рекомендации',
  outsourcing_personnel: 'Аутсорсинг персонала',

  // News
  news: 'Новости',
  show_all: 'Посмотреть все',

  // Favorites
  favourites: 'Избранные',
  be_in_favorites: 'В избранном',
  remove_from_favorites: 'Убрать из избранных',
  add_to_favorites: 'Добавить в избранное',

  // Responses and Invites
  responses_and_invitations: 'Отклики и приглашения',
  you_were_invited: 'Вас пригласили!',
  you_were_denied: 'Вам отказали',
  viewed: 'Просмотрено',
  not_viewed: 'Не просмотрено',
  response: 'Откликнуться',
  cancel_response: 'Отменить отклик',
  responded: 'Откликнулся',
  invited: 'Приглашен',
  refusal: 'Отказано',
  to_invite: 'Пригласить',
  refuse: 'Отказать',

  // Helpers
  topic: 'Тема обращения',
  help: 'Помощь',
  message_text: 'Сообщение',
  your_message: 'Ваше обращение',
  Writeto_the_technical_department: 'Написать в технический отдел',

  // Quastion Modal
  are_you_sure: 'Вы уверены?',

  // Login Modal
  login: 'Вход',
  login_button: 'Войти',
  authorization: 'Авторизация',
  authorization_button: 'авторизоваться',
  login_with: 'Войти через:',
  forgot_your_password: 'Забыли пароль?',
  register_on_site: 'ЗАРЕГИСТРИРОВАТЬСЯ НА САЙТЕ',

  // Register
  registration: 'Регистрация',
  register_button: 'ЗАРЕГИСТРИРОВАТЬСЯ',
  company: 'Компания',
  applicant: 'Соискатель',
  i_have_read_and_agree_with: 'Ознакомлен и согласен с',
  terms_of_agreement: 'условиями Договора',

  // Reset Password
  enter_your_password_recovery_email: 'Введите почту для восстановления пароля',

  // Time
  from: 'от',
  to: 'до',
  before: 'До',
  after: 'После',

  // =================================== Data ======================================
  // Person Data
  fullName: 'ФИО',
  name: 'Имя',
  surname: 'Фамилия',
  patronymic: 'Отчество',
  gender: 'Пол',
  man: 'Мужчина',
  woman: 'Женщина',
  additionally: 'Дополнительно',
  family_status: 'Семейное положение',
  children: 'Дети',

  // Common Data
  type_of_ownership: 'Форма собственности',
  name_company: 'Наименование компании',
  contact_person: 'Контактное лицо',
  bin_and_iin: 'БИН / ИИН',
  bin: 'БИН',
  KBE: 'КБе',
  IKK: 'Счет/ИИК',
  bank: 'Банк',
  BIK: 'БИК',
  KNP: 'КНП',
  legal_address: 'Юридический адрес',
  description: 'Описание',
  field_of_activity: 'Сфера деятельности',
  show_location_of_company_on_map: 'Укажите на карте местоположение компании',
  description_company: 'Описание компании',

  // Основная информация
  title: 'Название',
  basic_information: 'Основная информация',
  specialization: 'Специализация',

  // Email
  yourEmail: 'Ваша почта',

  // Password
  password: 'Пароль',
  repeat_password: 'Повторить пароль',

  // Phone
  phone: 'Телефон',
  second_phone: 'Дополнительный телефон',
  additional_contacts: 'Дополнительный способ связи',

  // Address
  address_title: 'Адрес',
  city: 'Город',
  country: 'Страна',
  region: 'Регион',
  choose_region: 'Выберите регион',
  choose_country: 'Выберите страну',
  choose_city: 'Выберите город',
  citizenship: 'Гражданство',
  country_origin: 'Страна проживания',
  city_origin: 'Город проживания',
  address_origin: 'Адрес проживания',
  show_address: 'Показывать адрес',
  location_on_map: 'Расположение на карте',
  work_permit: 'Разрешение на работу',
  place_of_workd: 'Место работы',
  where_to_look_for_an_employee: 'Где искать сотрудника',
  do_not_include_an_address: 'Не указывать адрес',
  specify_the_address: 'Указывать адрес',

  // Date and Birthday
  date_birth: 'Дата рождения',
  show_date_birth: 'Отображать дату рождения',
  pass_period: 'Период прохождения',
  until_now: 'по настоящее время',
  on: 'по',
  day: 'День',
  days: 'Дней',
  month: 'Месяц',
  year: 'Год',

  // State
  connected: 'Подключено',
  disabled: 'Отключено',
  not_set: 'Не задано',
  hidden: 'Скрыто',
  updated: 'Обновлено',
  published: 'Опубликовано',
  not_published: 'В архиве',
  all_changes_are_saved_in: 'Все изменения сохранены в',

  rofessional_skills: 'Профессиональные знания и навыки',
  more_about_yourself: 'Дополнительно о себе',

  // Hobbies
  Interests_and_hobbies: 'Интересы и увлечения',

  // Avto - Car
  availability_of_a_car: 'Наличие авто',
  own_car: 'Собственный автомобиль',
  driver_license: 'Водительские права',
  categories: 'Категории',
  category_of_rights: 'Категория прав',
  select_categories: 'Выберите категории',

  // Languages
  lang: 'Язык',
  language_skills: 'Знание языков',
  main_languages: 'Основные языки',
  specify_another_language: 'Указать еще один язык',

  // Issue Type
  issue_type: 'Тип выдачи',
  before_taxes: 'До вычета налогов',
  on_hand: 'На руки',

  // Employment Type
  employment_type: 'Тип занятости',
  industry: 'Отрасль',

  // Work
  working_mode: 'Режим работы',
  temporary_clearance_possible: 'Возможно временное оформление',
  it_is_possible_to_apply_for_gpc_or_part_time: 'Возможно оформление по ГПХ (услуги, подряд, ИП, самозанятые) или совместительству',
  estimated_income_level_per_month_or_per_amount_of_work: 'Предполагаемый уровень дохода в месяц или за объем работ',
  work_period: 'Период работы',
  functional_responsibilities: 'Функциональные обязанности',

  // Key skills
  key_skills: 'Ключевые навыки',
  select_skills: 'Выберите навыки',

  // Position
  position: 'Должность',
  career_objective: 'Желаемая должность',
  wage: 'Заработная плата',
  desired_salary: 'Желаемая заработная плата',

  // Ownership PC
  ownership_pc: 'Владение ПК',
  main_programs: 'Основные программы',

  // Experiences
  required_work_experience: 'Требуемый опыт работы',
  experience: 'Опыт работы',
  responsibilities: 'Обязанности',
  last_job: 'Последнее место работы',

  // Education
  education: 'Образование',
  honors_degree: 'Диплом с отличием',
  type_of_education: 'Вид образования',
  educational_institution: 'Учебное заведение',
  faculty: 'Факультет',
  speciality: 'Специальность',
  type_of_diploma: 'Вид диплома',
  training_period: 'Период обучения',

  // Courses
  courses: 'Курсы, тренинги, семинары',
  certificate: 'Сертификат',

  // Awards
  awards: 'НАГРАДЫ, ГРАМОТЫ',
  file: 'Файл',

  // Personal Slills
  personal_qualities: 'Личные качества',

  // Кто может откликаться
  who_can_respond: 'Кто может откликаться',
  applicants_with_disabilities: 'Соискатели с инвалидностью',
  applicants_with_an_incomplete_resume: 'Соискатели с неполным резюме',
  only_with_cover_letter: 'Только с сопроводительным письмом',

  // ================================= Placeholder =======================
  not_required_confidential: 'Не обязательно для заполнения, конфиденциально',
  list_separated_by_commas: 'Перечислите через запятую',
  describe_in_free_form: 'Опишите в свободной форме',

  // =================================== Button Texts ======================================
  // Hide and Not
  show: 'Отобразить',
  hide: 'Скрыть',
  look: 'Посмотреть',
  show_contacts: 'Показать контакты',
  buy: 'Купить',
  lets_go: 'Пройти',
  show_flyBtn: 'Показать',

  // Yes and No
  yes: 'Да',
  no: 'Нет',
  there_is: 'есть',
  edit: 'Изменить',
  cancel: 'Отмена',
  close: 'Закрыть',
  save_edited: 'СОХРАНИТЬ ИЗМЕНЕНИЯ',
  send_message: 'Отправить сообщение',
  add_more: 'Добавить еще',

  // Buttons
  // ///////////////////////
  send: 'Отправить',
  save: 'Сохранить',
  create: 'Создать',
  delete: 'Удалить',
  redact: 'Редактировать',
  edit_data: 'Редактировать данные',
  reach: 'Связаться',
  connect: 'Подключить',

  // Inputs
  // ///////////////////////
  enter_template: 'Введите название',
  name_template: 'Название шаблона',
  enter_text: 'Введите текст',

  // =================================== Select ==============================
  select_a_region_first: 'Сначала выберите регион',
  select_show_all: 'Показать все',
  not_found: 'не найден',

  // =================================== Company Pages ======================================
  // ///////////////////////
  common_data: 'Общие данные',

  // Managers
  manager: 'Менеджер',
  managers: 'Менеджеров',
  add_meneger: 'Добавить менеджера',
  company_menegers: 'Менеджеры компании',
  edit_meneger: 'Изменить менеджера',

  // Tests
  test: 'Тест',
  create_test: 'Создать тест',
  edit_test: 'Редактирование теста',
  name_test: 'Название теста',
  test_templates: 'Шаблоны тестов',
  add_quastion: 'Добавить вопрос',
  applicant_test: 'Тест для соискателя',
  select_tests: 'Выберите тесты',
  quastion: 'Вопрос',
  response_to_the_test: 'Ответ на тест',
  passing_the_test: 'Прохождение теста',

  // Letters
  letter_template: 'Шаблон письма',
  letter_templates: 'Шаблоны писем',
  create_latter: 'СОЗДАТЬ письмо',

  // Vacancies
  vacancy: 'Вакансия',
  vacancies: 'Вакансии',
  edit_vacancy: 'Редактировать вакансию',
  create_vacancy: 'Создать вакансию',
  about_the_position: 'О должности',
  name_vacancy: 'Название вакансии',
  tell_about_the_vacancy: 'Расскажите про вакансию',
  vacancy_adderss: 'Адрес вакансии',
  vacancy_codex: 'При публикации вакансии запрещается размещать информацию, содержащую требования дискриминационного характера в сфере труда (ограничения в зависимости от пола, возраста, места жительства и иных обстоятельств, не связанных с деловыми качествами и спецификой трудовой функции кандидата). Согласно п. 2 Ст. 6. Трудового Кодекса РК от 23 ноября 2015 года № 414-V и п. 3-.2 Ст. 14 Закона РК от 23 июля 1999 года № 451-I "О средствах массовой информации".',

  // Hot Vacancy
  hot_vacancy: {
    checkbox: 'Горящая вакансия',
    button: 'Создать горящую вакансию'
  },

  // Working With The Site
  working_with_site: 'Работа с сайтом',
  about_the_contract: 'О договоре',
  there_is_no_contract: 'Договор оказания услуг отсутствует',
  paid_bills: 'Оплаченные счета',
  connected_services: 'Подключенные услуги',
  responses_to_a_job: 'Отклики на вакансию',
  service_agreement: 'Договор оказания услуг',
  signed: 'Подписано',
  deadline_up_to: 'Срок до',
  vacancy_placement: 'Размещение вакансии',
  adding_a_manager: 'Добавление менеджера',
  left: 'Осталось',
  all_responses: 'Все отклики',

  // Connected Services
  number_of_available_vacancies: 'Количество доступных вакансий',
  number_of_available_managers: 'Количество доступных менеджеров',
  left_days: 'Осталось дней',

  // Search Resumes
  resumes: 'Резюме',
  applicants_resumes: 'Резюме соискателей',

  // Favorites Resumes
  favorites_resumes: 'Избранные резюме',

  // Services
  send_a_request_for_payment: 'Отправить заявку на оплату',
  denomination: 'Наименование',
  access_to_the_database_of_resume_of_applicants: 'Доступ к базе резюме соискателей',
  number_of_vacancies_to_post: 'Количество вакансий для размещения',
  number_of_company_managers: 'Количество менеджеров компании',
  service_cost: 'Стоимость услуг (KZT, включая НДС)',
  full_access: 'Полный доступ',
  no_access: 'Нет доступа',

  // =================================== Client Pages ======================================
  // Person data
  personal_data: 'Личные данные',
  edit_personal_data: 'Изменить личные данные',

  // Resumes
  resume: 'Резюме',
  my_resumes: 'Мои резюме',
  create_resume: 'Создать резюме',
  top_resume: 'Поднять резюме в топ!',

  // Companies
  companies: 'Компании',
  companies_directory: 'Каталог компаний',

  // Feedback form
  write_a_letter: 'Написать письмо',
  i_want_to_work_here: 'Хочу здесь работать',
  enter_your_cover_letter: 'Введите текст сопроводительного письма',
  select_resume: 'Выберите резюме',

  // Vacancies
  vacancies_of_this_company: 'Вакансии данной копании',

  // =================================== HELPERS DATAS ======================================
  // Months
  months: {
    1: 'Январь',
    2: 'Февраль',
    3: 'Март',
    4: 'Апрель',
    5: 'Май',
    6: 'Июнь',
    7: 'Июль',
    8: 'Август',
    9: 'Сентябрь',
    10: 'Октябрь',
    11: 'Ноябрь',
    12: 'Декабрь'
  },

  // Languages Levels
  languages_levels: {
    A1: 'A1 - Начальный',
    A2: 'A2 - Элементарный',
    B1: 'B1 - Средний',
    B2: 'B2 - Средне-продвинутый',
    C1: 'C1 - Продвинутый',
    C2: 'C2 - В совершенстве'
  },

  // Wages
  wages_option_1: 'до 100 000',
  wages_option_2: '100 000 - 200 000',
  wages_option_3: '200 000 - 300 000',
  wages_option_4: '300 000 - 400 000',
  wages_option_5: '400 000 - 500 000',
  wages_option_6: 'с 500 000',

  // Time Filter
  timeFilters: {
    day: 'За сегодня',
    month: 'За этот месяц',
    three_months: 'За три месяца',
    year: 'За год'
  },

  // Date Filter
  dateFilters: {
    date_asc: 'По возрастанию даты',
    date_descending: 'По убыванию даты'
  },

  // Filter Company
  filterCompanies: {
    all_companies: 'Все компании',
    only_with_open_vacancies: 'Только с открытыми вакансиями'
  },

  // Errors
  errors: {
    this_fieldIs_required: 'Это поле обязательно',
    enter_the_correct_number: 'Введите правильный номер',
    enter_the_correct_email: 'Введите правильную почту',
    enter_the_correct_password: 'Пароль должен содержать минимум 8 символов. В нем должны быть минимум, одна цифра,одна заглавная буква и одна маленькая буква',
    passwords_dont_match: 'Пароли не совпадают'
  }
}
