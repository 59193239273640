<template>
<div class="employeeServices__item">
  <div class="title">{{service.name}}</div>
  <div class="bottom">
    <div class="price">{{`${formatToSalary(service.price)} ${service.currency.title}`}}</div>
    <div class="btns">
      <Button
        :name="$t('pay')"
        :loading="loading"
        @click="sendRequestForPayment"
      />
    </div>
  </div>
</div>
</template>

<script setup>
import { formatToSalary } from '@/helpers/formatSalary.js'
import { defineProps, ref } from 'vue'

import { postRequestForPayment } from '@/actions/employee'

const props = defineProps({
  service: {
    type: Object,
    default: null
  }
})

const formData = {
  amount: props.service.price,
  currency: props.service.currency.title,
  service: props.service.name,
  success_url: window.location.origin + '/user/services/connecting-service/' + props.service.id,
  failure_url: window.location.href + '/user/services'
}
const loading = ref(false)

const sendRequestForPayment = async () => {
  loading.value = true
  try {
    const response = (await postRequestForPayment(formData)).data
    window.location.href = response.redirect_url
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss">
.employeeServices{
  &__item{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 258px;
    padding: 25px;
    border: 0.5px solid rgba(33, 37, 41, 0.7);
    text-align: center;
  }
  & .title,
  & .price{
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase;
    color: #212529;
  }

  & .price{
    margin-bottom: 30px;
  }

  & .btns{
    display: flex;
    justify-content: center;

    & .button.skeleton{
      min-width: 220px;
    }
  }
  @media (max-width: 768px){
    &__item{
      padding: 20px;
      min-height: auto !important;
      & .title{
        margin-bottom: 15px;
      }
    }
  }
  @media (max-width: 576px){
    &__item{
      padding: 15px;
      min-height: auto !important;
      & .title{
        margin-bottom: 15px;
      }
    }
    & .title,
    & .price{
      font-size: 18px;
    }
  }
  @media (max-width: 480px){
    &__item{
      padding: 12px;
    }
  }

  & .skeleton__block{
    border-color: rgba(0, 0, 0, 0.0955529) !important;

    & .titles,
    & .bottom{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    & .title,
    & .price{
      font-size: 16px;
    }
    & .btns{
      grid-template-columns: 1fr;
      & .button{
        width: 100%;
      }
    }
  }
}
</style>
