<template>
<div class="birthday" v-if="!period">
  <div class="birthday__item" @click="showDay = true">
    <div class="input">
      <input v-model="input.day" :placeholder="$t('day')" readonly/>
      <svg xmlns="http://www.w3.org/2000/svg" id="Filled" viewBox="0 0 24 24" width="512" height="512"><path d="M0,19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V10H0Zm17-4.5A1.5,1.5,0,1,1,15.5,16,1.5,1.5,0,0,1,17,14.5Zm-5,0A1.5,1.5,0,1,1,10.5,16,1.5,1.5,0,0,1,12,14.5Zm-5,0A1.5,1.5,0,1,1,5.5,16,1.5,1.5,0,0,1,7,14.5Z"/><path d="M19,2H18V1a1,1,0,0,0-2,0V2H8V1A1,1,0,0,0,6,1V2H5A5.006,5.006,0,0,0,0,7V8H24V7A5.006,5.006,0,0,0,19,2Z"/></svg>
    </div>
  </div>
  <div class="birthday__item" @click="showMonth = true">
    <div class="input">
      <input v-model="input.month" :placeholder="$t('month')" readonly/>
      <svg xmlns="http://www.w3.org/2000/svg" id="Filled" viewBox="0 0 24 24" width="512" height="512"><path d="M0,19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V10H0Zm17-4.5A1.5,1.5,0,1,1,15.5,16,1.5,1.5,0,0,1,17,14.5Zm-5,0A1.5,1.5,0,1,1,10.5,16,1.5,1.5,0,0,1,12,14.5Zm-5,0A1.5,1.5,0,1,1,5.5,16,1.5,1.5,0,0,1,7,14.5Z"/><path d="M19,2H18V1a1,1,0,0,0-2,0V2H8V1A1,1,0,0,0,6,1V2H5A5.006,5.006,0,0,0,0,7V8H24V7A5.006,5.006,0,0,0,19,2Z"/></svg>
    </div>
  </div>
  <div class="birthday__item" @click="showYear = true">
    <div class="input">
      <input v-model="input.year" :placeholder="$t('year')" readonly/>
      <svg xmlns="http://www.w3.org/2000/svg" id="Filled" viewBox="0 0 24 24" width="512" height="512"><path d="M0,19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V10H0Zm17-4.5A1.5,1.5,0,1,1,15.5,16,1.5,1.5,0,0,1,17,14.5Zm-5,0A1.5,1.5,0,1,1,10.5,16,1.5,1.5,0,0,1,12,14.5Zm-5,0A1.5,1.5,0,1,1,5.5,16,1.5,1.5,0,0,1,7,14.5Z"/><path d="M19,2H18V1a1,1,0,0,0-2,0V2H8V1A1,1,0,0,0,6,1V2H5A5.006,5.006,0,0,0,0,7V8H24V7A5.006,5.006,0,0,0,19,2Z"/></svg>
    </div>
  </div>
</div>

<div v-else class="birthday__item" :class="{disabled: disabled}" @click="disabled ? null : showYear = true">
  <div class="input">
    <input v-model="input.period" :placeholder="placeholder" readonly/>
    <svg xmlns="http://www.w3.org/2000/svg" id="Filled" viewBox="0 0 24 24" width="512" height="512"><path d="M0,19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V10H0Zm17-4.5A1.5,1.5,0,1,1,15.5,16,1.5,1.5,0,0,1,17,14.5Zm-5,0A1.5,1.5,0,1,1,10.5,16,1.5,1.5,0,0,1,12,14.5Zm-5,0A1.5,1.5,0,1,1,5.5,16,1.5,1.5,0,0,1,7,14.5Z"/><path d="M19,2H18V1a1,1,0,0,0-2,0V2H8V1A1,1,0,0,0,6,1V2H5A5.006,5.006,0,0,0,0,7V8H24V7A5.006,5.006,0,0,0,19,2Z"/></svg>
  </div>
</div>
<div v-if="showDay" class="bg" @click="showDay = !showDay"></div>

<transition name="show">
  <div class="overlay"
  v-if="showDay || showYear || showMonth" @click="showDay = false, showMonth = false, showYear = false"
  @keyup.esc="showDay = false, showMonth = false, showYear = false, input.toStart = true">
    <div class="calendar" @click.stop>
      <div class="calendar-body">
          <div class="calendar-week-day">
              <div v-for="day of ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']" :key="day">{{day}}</div>
          </div>
          <div class="calendar-days" :style="{opacity: period ? '0' : null}">
            <div v-for="item of moment(moment(date).format('YYYY') + '-' + moment(date).format('MM') + '-' + 1).day() === 0 ? 6 : moment(moment(date).format('YYYY') + '-' + moment(date).format('MM') + '-' + 1).day() - 1" :key="item"></div>
            <div
              v-for="day of moment(date).daysInMonth()" :key="day" :class="'calendar-day ' + (Number(moment(date).format('DD')) === day ? 'curr-date' : '')" @click="selectDay(day)">
              {{day}}
            </div>
          </div>
      </div>
      <div :class="'month-list ' + (showMonth ? 'show' : '')">
        <div class="month__inner">
          <div v-for="month of 12" :key="month">
            <div
              class="month-option"
              :class="{disabled: chackMonth(month)}"
              @click="selectMonth(month - 1)"
            >{{monthNames[month - 1]}}</div>
          </div>
        </div>
      </div>
      <div class="year-list" :class="{show: showYear}">
        <div class="year__inner">
          <div v-for="year of 100" :key="year">
            <div
              class="year-option"
              :class="{disabled: chackYear((Number(moment().format('YYYY')) - 100) + year)}"
              @click="selectYear((Number(moment().format('YYYY')) - 100) + year)"
            >
              {{(Number(moment().format('YYYY')) - 100) + year}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</transition>
</template>

<script setup>
import moment from 'moment'
import { reactive, ref } from 'vue-demi'
import { defineProps, defineEmits, watch, onMounted, onUnmounted } from 'vue'

const props = defineProps({
  modelValue: {
    type: [String, Number, Object],
    default: null
  },
  validation: {
    type: Boolean,
    default: false
  },
  period: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  maxDate: {
    type: String,
    default: null
  },
  minDate: {
    type: String,
    default: null
  },
  placeholder: {
    type: String,
    default: ''
  }
})
const emit = defineEmits(['update:modelValue', 'update:validation', 'datePicker'])

const input = reactive({
  day: '',
  month: '',
  year: '',
  period: '',
  max: {
    year: Number(moment().format('YYYY')),
    month: Number(moment().format('MM'))
  },
  min: {
    year: Number(moment().format('YYYY')) - 100,
    month: Number(moment().format('MM'))
  }
})

const date = ref(new Date(Number(moment().format('YYYY')) - 100, Number(moment().format('MM')) - 1, moment().format('DD')))
const showDay = ref(false)
const showMonth = ref(false)
const showYear = ref(false)
const monthNames = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']

const newDate = () => {
  input.year = moment(date.value).format('YYYY')
  input.month = moment(date.value).format('MM')
  input.day = moment(date.value).format('DD')
  input.period = monthNames[Number(moment(date.value).format('MM')) - 1] + ' ' + moment(date.value).format('YYYY')
  emit('update:modelValue', input.year + '-' + input.month + '-' + input.day)
  emit('update:validation', true)
}
const selectYear = (year) => {
  showYear.value = false
  date.value = new Date(year, Number(moment(date.value).format('MM')) - 1, moment(date.value).format('DD'))
  newDate()
  showDay.value = false
  if (props.period) showMonth.value = true
}
const selectMonth = (month) => {
  showMonth.value = false
  date.value = new Date(moment(date.value).format('YYYY'), month, moment(date.value).format('DD'))
  newDate()
}
const selectDay = (day) => {
  date.value = new Date(moment(date.value).format('YYYY'), Number(moment(date.value).format('MM')) - 1, day)
  newDate()
  showDay.value = false
}

const toTypeDate = input => {
  return new Date(input.substring(0, 4), input.substring(5, 7), input.substring(8, 10))
}

const updateMaxAndMin = () => {
  if (props.maxDate) {
    input.max.year = Number(props.maxDate.substring(0, 4))
    input.max.month = Number(props.maxDate.substring(5, 7))
  }
  if (props.minDate) {
    input.min.year = Number(props.minDate.substring(0, 4))
    input.min.month = Number(props.minDate.substring(5, 7))
  }
}

if (props.modelValue) {
  date.value = toTypeDate(props.modelValue)
  updateMaxAndMin()
  newDate()
}

watch(
  () => [props.maxDate, props.minDate],
  () => {
    updateMaxAndMin()
  }
)

const chackYear = (year) => {
  if (props.minDate) return year < input.min.year
  else return year > input.max.year
}

const chackMonth = (month) => {
  if (props.minDate) return Number(input.year) === input.min.year && month < input.min.month
  else return Number(input.year) === input.max.year && month > input.max.month
}
const hideModalOnEsc = (e) => {
  if (e.keyCode === 27) {
    showDay.value = false
    showMonth.value = false
    showYear.value = false
  }
}
onMounted(() => {
  document.addEventListener('keydown', hideModalOnEsc)
  if (props.show) {
    document.querySelector('body').classList.add('no-scroll')
  }
})
onUnmounted(() => {
  document.removeEventListener('keydown', hideModalOnEsc)
  document.querySelector('body').classList.remove('no-scroll')
})
watch(
  () => showDay,
  () => {
    if (showDay) {
      document.querySelector('body').classList.add('no-scroll')
    } else {
      document.querySelector('body').classList.remove('no-scroll')
    }
  }
)
</script>

<style lang="scss" scoped>
input {
  width: 100%;
  font-size: 16px;
  line-height: 1.5;

  padding: 10px 20px 11px;
  border: 0.5px solid rgba(33, 37, 41, 0.7);
  position: relative;
  z-index: 1;
}
.birthday{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  &__item{
    transition: opacity .2s linear;
    &.disabled{
      opacity: .5;
      * {
        cursor: default;
      }
      &:hover{
        & input{
          border: 0.5px solid rgba(33, 37, 41, 0.7) !important;
        }
        & svg{
          opacity: .4 !important;
        }
      }
    }
    &:hover{
      & .input{
        & input{
          border-color: #0077FF;
        }
        & svg{
          opacity: .6;
        }
      }
    }
    & input{
      cursor: pointer;
    }
    & .input{
      position: relative;
      & svg{
        display: block;
        width: 15px;
        height: 15px;
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        opacity: .4;
        transition: opacity .2s linear;
      }
    }
  }
}
.overlay{
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  height: 100vh;
  width: 100%;
  background-color: rgba(#000, .5);

  display: flex;
  justify-content: center;
  align-items: center;
}
.calendar {
    height: max-content;
    width: 300px;
    background-color: #fdfdfd;
    border-radius: 30px;
    padding: 15px;
    position: relative;
    overflow: hidden;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 19px;
    font-weight: 600;
    color: #151426;
    padding: 7px;
}

.calendar-body {
    padding: 10px;
}

.calendar-week-day {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    margin-bottom: 10px;
}

.calendar-week-day div {
  display: grid;
  place-items: center;
  font-size: 12px;
  font-weight: 600;
  color: var(#c3c2c8);
  opacity: .8;
}

.calendar-days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px 5px;
    color: #151426;
}

.calendar-days .calendar-day {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    font-size: 14px;
    justify-content: center;
    padding: 2px;
    position: relative;
    cursor: pointer;
    border-radius: 50%;
    text-align: center;
    border: 1.5px solid transparent;
    animation: to-top 1s forwards;
    transition: all .2s linear;
}

.calendar-days .calendar-day:hover{
    border-color: #000;
}

.calendar-days .calendar-day.curr-date,
.calendar-days .calendar-day.curr-date:hover {
    background-color: #0000ff;
    color: #fff;
    border-color: transparent;
}

.month-picker {
    font-weight: 500;
    font-size: 20px;
    padding: 5px 10px;
    border-radius: 10px;
    cursor: pointer;
}
.year-picker {
    display: flex;
    align-items: center;
}

.year-picker button {
    font-weight: 500;
}

.year-picker span {
    line-height: 1;
    cursor: pointer;
}

.year-change {
    height: 40px;
    width: 40px;
    font-size: 20px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    margin: 0 10px;
    cursor: pointer;
}
.month-picker:hover,
.year-change:hover {
    background-color: #edf0f5;
}

.year-change[disabled]{
  opacity: .7;
  pointer-events: none;
  background-color: transparent;
}

.month-list,
.year-list {
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: #fdfdfd;
  transform: scale(1.5);
  visibility: hidden;
  pointer-events: none;
}

.month-list.show,
.year-list.show {
  z-index: 1;
  transform: scale(1);
  visibility: visible;
  pointer-events: visible;
  transition: all 0.3s linear;
}

.year-list{
  padding: 30px;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
  & .year__inner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
  }

  & .year-option {
    cursor: pointer;
    width: 100%;
    padding: 3px 0;

    color: #4f4f4f;
    font-size: 12px;
    text-align: center;

    border: 1.5px solid transparent;
    border-radius: 3px;
    background-color: rgba(#ededed, 1);

    transition: all .1s ease;

    &:hover{
      background-color: rgba(#A22E2B, 1);
      color: #fff;
    }
  }
}

.month__inner{
  height: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3px;
  display: grid;
  padding: 15px;
}

.month-list > div {
    display: grid;
    place-items: center;
}

.month-list  .month-option {
    width: 100%;
    padding: 5px 15px;
    border-radius: 10px;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    color: #151426;
}

.month-list .month-option:hover {
    background-color: #edf0f5;
}

.disabled{
  opacity: .6;
  pointer-events: none;
}

@keyframes to-top {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.show-enter-active,
.show-leave-active {
  transition: opacity .2s linear;
}
.show-enter-from,
.show-leave-to {
  opacity: 0;
}

@media (max-width: 992px){
  input {
    font-size: 14px;
    padding: 9px 18px 10px;
  }
  .birthday{
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
    &__item .input svg{
      width: 13px;
      height: 13px;
      right: 11px;
    }
  }
}
@media (max-width: 768px){
  input {
    font-size: 13px;
    padding: 8px 16px 9px;
  }
  .birthday{
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    &__item .input svg{
      width: 10px;
      height: 10px;
      right: 11px;
    }
  }
}
@media (max-width: 576px){
  input {
    font-size: 12px;
    padding: 7px 14px 8px;
  }
  .birthday{
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
    &__item .input svg{
      width: 13px;
      height: 13px;
      right: 11px;
    }
  }
}
</style>
