<template>
<div class="createResume__itemRow">
  <DeleteButton @click="() => {$emit('onDelete')}" />
  <div class="createResume__itemInner">
    <label for="prof">{{$t('title')}}:</label>
    <Input
      id="title"
      v-model="formData.name"
      required
    />
  </div>
  <div class="createResume__itemInner">
    <label for="file">{{$t('file')}}:</label>
    <Input
      id="title"
      :placeholder="$t('link_to_file')"
      v-model="formData.file"
    />
  </div>
  <div class="createResume__itemInner">
    <label for="skills">{{$t('description')}}:</label>
    <Input
      id="title"
      type="textarea"
      v-model="formData.description"
    />
  </div>
</div>
</template>

<script setup>
import DeleteButton from '@/components/common/Buttons/DeleteButton.vue'

import { defineProps, defineEmits, ref, watch, onMounted } from 'vue'
import { getCities } from '@/actions/help'

const props = defineProps({
  award: {
    type: Object,
    default: () => {}
  }
})
const emit = defineEmits(['update:modelValue', 'update:validation', 'onDelete'])

const formData = ref(props.award)

watch(
  () => formData.value,
  () => {
    emit('update:modelValue', formData.value)
  }
)

const city = ref(null)
onMounted(
  async () => {
    if (formData.value.country_id) city.value = (await getCities(formData.value.country_id)).data.cities
  }
)
watch(
  () => formData.value.country_id,
  async () => {
    if (formData.value.country_id) city.value = (await getCities(formData.value.country_id)).data.cities
  }
)
</script>
