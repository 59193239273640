import api from '../modules/api'

function splitString (stringToSplit) {
  let arrayOfStrings = ''
  if (stringToSplit) {
    arrayOfStrings = stringToSplit.split('-')
    return arrayOfStrings.join('')
  }
}

export const getToken = (email, password) => {
  return api.asyncPost('/auth/login', { email, password })
}

export const deleteToken = () =>
  api.asyncGet('/auth/logout')

export const postRegisterUser = (data) => {
  data.role_id = 3
  return api.asyncPost('/auth/register/user', data)
}

export const postRegisterCompany = (data) => {
  data.BIN = splitString(data.BIN)
  return api.asyncPost('/auth/register/company', data)
}
export const postForgotPassword = (email) =>
  api.asyncPost('/auth/password/forgot', { email })

export const getSocialNetwork = socialNetwork => {
  return api.asyncGet('/auth/socialite/redirect/' + socialNetwork, {}, false, true)
}
