import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import AboutUs from '@/views/AboutUs.vue'
import StockDetail from '@/views/StockDetail.vue'

// News
import News from '@/views/news/News.vue'
import NewsDetail from '@/views/news/NewsDetail.vue'

// Services
import ServiceDetail from '@/views/services/ServiceDetail.vue'
import OutsourcingPersonnel from '@/views/services/outsourcing/OutsourcingPersonnel.vue'
import OutsourcingDetail from '@/views/services/outsourcing/OutsourcingDetail.vue'

import Contacts from '@/views/Contacts.vue'
import NotFound from '@/views/404NotFound.vue'

// Hot Vacancy Detail
import HotVacanciesDetail from '@/views/HotVacanciesDetail.vue'

// ====================  Client Login  ====================
import PersonalData from '@/views/employee/personal-data/PersonalData.vue'
import EditPersonalData from '@/views/employee/personal-data/EditPersonalData.vue'

// Resumes
import MyResume from '@/views/employee/resumes/MyResumes.vue'
import CreateResume from '@/views/employee/resumes/CreateResume.vue'
import ResumeDetail from '@/views/employee/resumes/ResumeDetail.vue'

// Responses
import Responses from '@/views/employee/Responses.vue'

// Invitation
import Invitation from '@/views/employee/Invitation.vue'

// Favorites
import Favorites from '@/views/employee/Favorites.vue'

// Vacancies
import SearchVacancy from '@/views/employee/searchVacancy/SearchVacancy.vue'

// Companies
import SearchCompany from '@/views/employee/searchCompany/SearchCompany.vue'
import DetailCompany from '@/views/employee/searchCompany/DetailCompany.vue'

// Passing The Test
import PassingTheTest from '@/views/employee/PassingTest.vue'

// Services
import Services from '@/views/employee/Services/Services.vue'
import ClientServices from '@/views/employee/Services/MyServices.vue'

// ====================  Company Login  ====================
import CommonData from '@/views/company/common-data/CommonData.vue'
import EditCommonData from '@/views/company/common-data/EditCommonData.vue'

// Managers
import CreateAndEditManagers from '@/views/company/managers/CreateAndEditManagers.vue'
import CompanyManagers from '@/views/company/working-with-site/CompanyManagers.vue'

// Resumes
import SearchResumes from '@/views/company/resumes/SearchResumes.vue'
import FavoritesResumes from '@/views/company/resumes/FavoritesResumes.vue'

// Send Request For Payment
import CompanyPayment from '@/views/company/Payment.vue'

// Tests
import TestsList from '@/views/company/tests/TestsList.vue'
import TestTemplate from '@/views/company/tests/CreateAndEditTest.vue'

// Letters
import LettersList from '@/views/company/letters/LettersList.vue'

import CompanyServices from '@/views/company/Services.vue'

// Vacancies
import CompanyVacancies from '@/views/company/vacancies/MyVacancies.vue'
import CreateAndEditVacancy from '@/views/company/vacancies/CreateAndEditVacancy.vue'

// Working With Site
import WorkingWithSite from '@/views/company/working-with-site/WorkingWithSite.vue'
import FillingInBillingInformation from '@/views/company/working-with-site/FillingInBillingInformation.vue'
import ResponsesForVacancy from '@/views/company/working-with-site/Responses.vue'
import ConnectedServices from '@/views/company/working-with-site/ConnectedServices.vue'

// ====================  Client and Company  ====================
import Help from '@/views/Help.vue'
import DetailVacancy from '@/views/company/vacancies/DetailVacancy.vue'

// ====================  Consultant Login  ====================
// Companies
import ListCompanies from '@/views/consultant/Companies.vue'

// Services
import ListServices from '@/views/consultant/Services.vue'

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/news/:filter?',
    name: 'News',
    component: News
  },
  {
    path: '/detail-news/:news_id',
    name: 'newsDetail',
    component: NewsDetail
  },
  {
    path: '/authorization/:from?',
    name: 'authorization',
    component: Home,
    meta: { title: 'A-Class' }
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts
  },
  {
    path: '/services/:service_id',
    name: 'ServiceDetail',
    component: ServiceDetail
  },
  {
    path: '/services/outsourcing-personnel',
    name: 'outsourcingPersonnel',
    component: OutsourcingPersonnel
  },
  {
    path: '/services/outsourcing-personnel/:outsourcing_id',
    name: 'OutsourcingDetail',
    component: OutsourcingDetail
  },
  {
    path: '/history',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/burning-vacancies/:burning_vacancie_id',
    name: 'HotVacanciesDetail',
    component: HotVacanciesDetail
  },
  {
    path: '/stock/:stock_id',
    name: 'StockDetail',
    component: StockDetail
  },
  // CLIENT ROUTES
  {
    path: '/user/personal-data',
    name: 'PersonalData',
    component: PersonalData
  },
  {
    path: '/user/edit-personal-data',
    name: 'EditPersonalData',
    component: EditPersonalData
  },
  {
    path: '/user/help',
    name: 'UserHelp',
    component: Help
  },
  {
    path: '/user/favorites/:filter?',
    name: 'Favorites',
    component: Favorites
  },
  {
    path: '/user/responses/:filter?',
    name: 'Responses',
    component: Responses
  },
  {
    path: '/user/invitations/:invitation_id',
    name: 'Invitation',
    component: Invitation
  },
  {
    path: '/user/my-resumes/:filter?',
    name: 'MyResume',
    component: MyResume
  },
  {
    path: '/user/detail-resume/:resume_id/:from?',
    name: 'MyResumeDetail',
    component: ResumeDetail
  },
  {
    path: '/user/edit-resume/:resume_id',
    name: 'EditResume',
    component: CreateResume
  },
  {
    path: '/user/create-resume',
    name: 'CreateResume',
    component: CreateResume
  },
  {
    path: '/user/search-company/:filter?',
    name: 'SearchCompany',
    component: SearchCompany
  },
  {
    path: '/user/detail-company/:company_id',
    name: 'DetailCompany',
    component: DetailCompany
  },
  {
    path: '/user/search-vacancy/:filter?',
    name: 'SearchVacancy',
    component: SearchVacancy
  },
  {
    path: '/user/detail-vacancy/:vacancy_id',
    name: 'DetailVacancy',
    component: DetailVacancy
  },
  {
    path: '/user/detail-vacancy/:vacancy_id/passing-the-test',
    name: 'PassingTheTest',
    component: PassingTheTest
  },
  {
    path: '/user/services',
    name: 'Services',
    component: Services
  },
  {
    path: '/user/services/connected-services/:service_id?',
    name: 'ClientServices',
    component: ClientServices
  },
  {
    path: '/user/services/connecting-service/:service_id',
    name: 'ConnectingService',
    component: ClientServices
  },
  // Company ROUTES
  {
    path: '/company/common-data',
    name: 'CommonData',
    component: CommonData
  },
  {
    path: '/company/edit-common-data',
    name: 'EditCommonData',
    component: EditCommonData
  },
  {
    path: '/company/create-manager',
    name: 'CreateManager',
    component: CreateAndEditManagers
  },
  {
    path: '/company/search-resumes/:filter?',
    name: 'SearchResumes',
    component: SearchResumes
  },
  {
    path: '/company/favorites-resumes/:filter?',
    name: 'FavoritesResumes',
    component: FavoritesResumes
  },
  {
    path: '/company/resume-detail/:resume_id',
    name: 'SearchResumeDetail',
    component: ResumeDetail
  },
  {
    path: '/company/working-with-the-site',
    name: 'workingWithSite',
    component: WorkingWithSite
  },
  {
    path: '/company/working-with-the-site/filling-in-billing-information',
    name: 'FillingInBillingInformation',
    component: FillingInBillingInformation
  },
  {
    path: '/company/working-with-the-site/company-managers/:filter?',
    name: 'CompanyManagers',
    component: CompanyManagers
  },
  {
    path: '/company/working-with-the-site/connected-services/:filter?',
    name: 'ConnectedServices',
    component: ConnectedServices
  },
  {
    path: '/company/working-with-the-site/vacancy-responses/:filter?',
    name: 'ResponsesForVacancy',
    component: ResponsesForVacancy
  },
  {
    path: '/company/working-with-the-site/vacancy-response/:response_id/resume-detail',
    name: 'ResponseResume',
    component: ResumeDetail
  },
  {
    path: '/company/services',
    name: 'CompanyServices',
    component: CompanyServices
  },
  {
    path: '/company/services/send-a-request-for-payment/:service_id',
    name: 'CompanyPayment',
    component: CompanyPayment
  },
  {
    path: '/company/letters-templates/:filter?',
    name: 'LettersList',
    component: LettersList
  },
  {
    path: '/company/test-templates/:filter?',
    name: 'TestsList',
    component: TestsList
  },
  {
    path: '/company/create-test',
    name: 'CreateTest',
    component: TestTemplate
  },
  {
    path: '/company/edit-test/:test_id',
    name: 'TestDetail',
    component: TestTemplate
  },
  {
    path: '/company/edit-manager/:manager_id',
    name: 'EditManager',
    component: CreateAndEditManagers
  },
  {
    path: '/company/search-vacancy/:filter?',
    name: 'CompanyVacancies',
    component: CompanyVacancies
  },
  {
    path: '/company/edit-vacancy/:vacancy_id?',
    name: 'CreateAndEditVacancy',
    component: CreateAndEditVacancy
  },
  {
    path: '/company/detail-vacancy/:vacancy_id',
    name: 'DetailCompanyVacancy',
    component: DetailVacancy
  },
  {
    path: '/company/help',
    name: 'CompanyHelp',
    component: Help
  },
  // Consultant ROUTES
  {
    path: '/consultant/list-companies/:filter?',
    name: 'ListCompanies',
    component: ListCompanies
  },
  {
    path: '/consultant/list-services/:company_id',
    name: 'ListServices',
    component: ListServices
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
