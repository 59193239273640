<template>
  <Header />
  <section class="editPersonalData">
    <div class="container">
        <h3 class="section__title">{{$t('edit_personal_data')}}</h3>
        <div class="editPersonalData__block" v-if="formData.name && country">
            <UploadImage v-model="formData.photo" @onUpdated="() => {fetchData()}"/>
            <form class="editPersonalData__form" @submit.prevent="updatePersonalData()" method="POST">
              <div>
                  <label for="surname">{{$t('surname')}}:</label>
                  <Input
                    id="surname"
                    v-model="formData.surname"
                    v-model:validation="success.surname"
                    required/>
              </div>

              <div>
                  <label for="name">{{$t('name')}}:</label>
                  <Input id="name"
                    v-model="formData.name"
                    v-model:validation="success.name"
                    required/>
              </div>

              <div>
                  <label for="patronymic">{{$t('patronymic')}}:</label>
                  <Input
                    id="patronymic"
                    v-model="formData.patronym"
                  />
              </div>

              <div>
                <label for="date">{{$t('date_birth')}}</label>
                <DatePicker
                  v-model="formData.birthday"
                  v-model:validation="success.birthday"
                />
              </div>

              <div>
                  <label>{{$t('show_date_birth')}}:</label>
                  <div class="checkbox__items">
                    <div class="checkbox__item" @click="formData.show_birth = 0">
                        <div class="checkbox" :class="{ active: formData.show_birth === 0 }"></div>
                        <div class="text">{{$t('hide')}}</div>
                    </div>
                    <div class="checkbox__item" @click="formData.show_birth = 1">
                        <div class="checkbox" :class="{ active: formData.show_birth === 1}"></div>
                        <div class="text">{{$t('show')}}</div>
                    </div>
                  </div>
              </div>

              <div>
                  <label>{{$t('gender')}}:</label>
                  <div class="checkbox__items">
                    <div class="checkbox__item" @click="formData.sex = 1">
                        <div class="checkbox" :class="{ active: formData.sex === 1 }"></div>
                        <div class="text">{{$t('man')}}</div>
                    </div>
                    <div class="checkbox__item" @click="formData.sex = 0">
                        <div class="checkbox" :class="{ active: formData.sex === 0 }"></div>
                        <div class="text">{{$t('woman')}}</div>
                    </div>
                  </div>
              </div>

              <div>
                  <label for="country">{{$t('country_origin')}}:</label>
                  <Select
                    id="country"
                    :options="country"
                    v-model="formData.country_id"/>
              </div>

              <div>
                  <label for="city">{{$t('city_origin')}}:</label>
                  <Select
                    id="city"
                    :options="city"
                    v-model="formData.city_id"/>
              </div>

              <div>
                  <label for="address">{{$t('address_origin')}}:</label>
                  <Input
                    id="address"
                    v-model="formData.address"/>
              </div>

              <div>
                  <label>{{$t('show_address')}}:</label>
                  <div class="checkbox__items">
                    <div class="checkbox__item" @click="formData.show_address = 0">
                        <div class="checkbox" :class="{ active: formData.show_address === 0 }"></div>
                        <div class="text">{{$t('hide')}}</div>
                    </div>
                    <div class="checkbox__item" @click="formData.show_address = 1">
                        <div class="checkbox" :class="{ active: formData.show_address === 1 }"></div>
                        <div class="text">{{$t('show')}}</div>
                    </div>
                  </div>
              </div>

              <div>
                  <label for="email">Email:</label>
                  <Input
                    id="email"
                    type="email"
                    v-model="formData.email"
                    required disabled/>
              </div>

              <div>
                  <label for="phone">{{$t('phone')}}:</label>
                  <Input id="phone"
                    type="tel"
                    v-model="formData.phone"
                    disabled
                    required/>
              </div>

              <div>
                  <label for="second_phone">{{$t('second_phone')}}:</label>
                  <Input
                    id="second_phone"
                    type="tel"
                    v-model="formData.second_phone"/>
              </div>

              <div>
                  <label for="additional_contacts">{{$t('additional_contacts')}}:</label>
                  <Input
                    id="additional_contacts"
                    type="textarea"
                    v-model="formData.additional_contacts"/>
              </div>

              <div>
                  <div></div>
                  <div class="btns">
                    <Button
                      :name="$t('save_edited')"
                      htmlType="submit"
                      :hasError="success"
                    />

                    <Button
                      type="gray"
                      :name="$t('cancel')"
                      go="/user/personal-data"
                      noButton/>
                  </div>
              </div>
          </form>
        </div>
        <div class="editPersonalData__block" v-else>
          <div class="personalData__preview">
            <div class="img skeleton skeleton-img"></div>
          </div>
          <div class="editPersonalData__form">
            <div v-for="item of 9" :key="item">
              <label class="skeleton middle-text"></label>
              <div class="skeleton">
                <Input disabled/>
              </div>
            </div>
          </div>
        </div>
    </div>
  </section>
  <Loading :show="loading" fixed />
</template>

<script setup>
import DatePicker from '@/components/common/DatePickers/DatePicker.vue'
import UploadImage from '@/components/common/Upload/Image/UploadImage.vue'

import { reactive, ref } from '@vue/reactivity'
import router from '@/router/index.js'
import { onMounted, watch } from 'vue-demi'

import { getCountries, getCities } from '@/actions/help'
import { getPersonalData, putPersonalData } from '@/actions/employee'

const loading = ref(false)
const formData = ref({})
const success = reactive({
  surname: false,
  name: false,
  birthday: false
})

// ====================  Api Countries ans Cities / Personal Data ====================
const fetchData = async () => {
  formData.value = {}
  formData.value = await getPersonalData()
}
const country = ref(null)
const city = ref(null)

onMounted(
  async () => {
    country.value = (await getCountries()).data
    await fetchData()
    if (formData.value.country_id) city.value = (await getCities(formData.value.country_id)).data.cities
  }
)
watch(
  () => formData.value.country_id,
  async () => {
    if (formData.value.country_id) city.value = (await getCities(formData.value.country_id)).data.cities
  }
)

// ====================  update My Personal Data  ====================
async function updatePersonalData () {
  loading.value = true
  try {
    await putPersonalData(formData.value)
    router.push('/user/personal-data')
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss">
.editPersonalData{
  & label{
    font-size: 16px;
    line-height: 25px;
    color: rgba(33, 37, 41, 0.7);
  }

  & textarea{
    resize: vertical;
  }

  &__preview{
    & .skeleton{
      max-height: 320px;
    }
  }
  &__block{
    display: grid;
    grid-template-columns: 320px 1fr;
    grid-gap: 60px;
  }

  &__name{
    font-size: 36px;
    line-height: 26px;
    color: #212529;
    margin-bottom: 30px;
  }
  &__form{
    & > div{
      display: grid;
      grid-template-columns: 155px max(440px);
      grid-gap: 20px;
      align-items: center;
      margin-bottom: 20px;
    }

    & .btns{
      display: flex;
      grid-gap: 20px;
    }
  }

  & .checkbox{
    width: 22px;
    height: 22px;
    background: #FFFFFF;
    border: 1px solid rgba(33, 37, 41, 0.2);
    border-radius: 100px;
    position: relative;
    cursor: pointer;

    &.active::after{
      background-color: #AD1717;
    }

    &::after{
      content: "";
      display: block;
      height: 14px;
      width: 14px;
      border-radius: 100px;
      background-color: transparent;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: background-color .2s linear;
    }

    &__items{
      display: flex;
      align-items: center;
      margin: 10px 0;
    }

    &__item{
      width: 110px;
      display: grid;
      grid-template-columns: 22px 1fr;
      grid-gap: 20px;
      align-items: center;
      margin-right: 20px;
      cursor: pointer;

      & .text{
        font-size: 16px;
        line-height: 1.1;
        color: rgba(33, 37, 41, 0.7);
        cursor: pointer;
      }
    }
  }

  @media (max-width: 1200px){
    &__block{
      grid-template-columns: 1fr;
    }
    &__preview{
      margin: 0 auto;
    }
    &__form{
      & > div{
        grid-template-columns: 155px 1fr;
      }
    }
  }

  @media (max-width: 992px){
    &__form  > div{
      grid-template-columns: 150px 1fr;
    }
  }

  @media (max-width: 768px){
    &__form  > div{
      grid-template-columns: 1fr;
      grid-gap: 15px 0;
      margin-bottom: 15px;
    }
    & label{
      margin-top: 0 !important;
      font-size: 14px;
    }
  }
  @media (max-width: 576px){
    & label{
      font-size: 12px;
    }

    &__block{
      grid-gap: 40px;
    }
    &__form > div {
      grid-template-columns: 1fr !important;
      grid-gap: 2px;
      margin-bottom: 10px;
    }
    & .btns{
      grid-column: 1/2;
    }
  }
  @media (max-width: 395px){
    & .btns{
      flex-direction: column;
      grid-gap: 10px !important;
    }
  }
}

</style>
