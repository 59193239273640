<template>
<div>
  <Modal
    :title="'ПРИГЛАСИТЕЛЬНОЕ ПИСЬМО'"
    :hide="()=>{$emit('hide')}"
    :show="show"
    :loading="loading"
  >
    <form class="acceptModal" @submit.prevent="checkLettersName()" method="POST" v-if="lettersList">
      <Select
        class="acceptModal__select"
        :options="lettersList"
        v-model="letter.id"
        v-model:option="letter"
        v-model:validation="success.select"
      />

      <Input
        class="acceptModal__textarea"
        type="textarea"
        v-model="letter.text"
        :placeholder="$t('description')"
        disabled
      />

      <Button
        class="acceptModal__btn"
        :name="$t('send')"
        htmlType="submit"
        :disabled="loading"
        :hasError="success"
      />
    </form>
  </Modal>
</div>
</template>

<script setup>
import Modal from '@/components/common/Modal.vue'

import { defineProps, onMounted, reactive, ref, watch, defineEmits } from 'vue'
import { getLettersShow, postLetter, putInviteResponse } from '@/actions/company'

const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  responseId: {
    type: Number,
    default: null
  }
})

const emit = defineEmits(['hide', 'update:state'])

const loading = ref(false)
const letter = ref(
  {
    id: null,
    text: '',
    options: null
  }
)
const lettersList = ref(null)
const letterOriginal = ref(null)

const success = reactive({
  select: false
})

onMounted(
  async () => {
    lettersList.value = (await getLettersShow()).data.letters
    letter.value.id = lettersList.value[0].id
    letter.value.text = lettersList.value[0].text
    loading.value = false
  }
)

watch(
  () => letter.value.text,
  () => {
    if (letter.value.text.length === 0) success.text = false
    else success.text = true
  }
)

watch(
  () => letter.value.id,
  () => {
    letterOriginal.value = Object.assign({}, letter.value)
  }
)

// ====================  Send Response  ====================
const checkLettersName = async (num = 1) => {
  if (letterOriginal.value.text !== letter.value.text) {
    // check letters name
    let found = false
    lettersList.value.forEach(item => {
      if (item.title === letterOriginal.value.title + '.' + num) {
        found = true
        checkLettersName(num + 1)
      }
    })

    // Create new letter
    if (!found) {
      letterOriginal.value.title = letterOriginal.value.title + '.' + num
      createLetter()
    }
  } else sendResponse()
}

// =================== Creat Letter ===============
const createLetter = async () => {
  loading.value = true
  const response = await postLetter(
    {
      title: letterOriginal.value.title,
      text: letterOriginal.value.text
    }
  )
  letterOriginal.value.id = response.id
  sendResponse()
}

// ====================  Send Response  ====================
const sendResponse = async () => {
  loading.value = true
  try {
    await putInviteResponse(props.responseId, { letter_id: letterOriginal.value.id })
    emit('update:state', 'Accepted')
    emit('hide')
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss" scoped>
.acceptModal{
  &__select{
    margin-bottom: 20px;
  }
  &__textarea{
    margin-bottom: 40px;
  }
  &__btn{
    margin: 0 auto;
  }
}
</style>
