<template>
  <Header />
  <section class="workingWithSite">
    <div class="container">
      <h3 class="section__title">{{$t('working_with_site')}}</h3>
      <div class="workingWithSite__block" v-if="managers.length !== 0">

        <!--  =============  About Contract - О договоре  ============== -->
        <WorkingWithSitefrom
          :blockTitle="$t('about_the_contract')"
          :data="contract"
          :print="contractPdf ? `${apiUrl}${contractPdf}` : null"
        />

        <!--  =============  Responses - Отклики на вакансию  ============== -->
        <WorkingWithSitefrom
          :blockTitle="$t('responses_to_a_job')"
          :data="responses"
          buttonLink="/company/working-with-the-site/vacancy-responses/result?search=&type&vacancy&page=1"
        />

        <!--  =============  Connected services - Подключенные услуги  ============== -->
        <WorkingWithSitefrom
          :blockTitle="$t('connected_services')"
          :data="connectedServices"
          buttonLink="/company/working-with-the-site/connected-services/result?page=1"
        />

        <!--  =============  Managers - Менеджеры компании  ============== -->
        <WorkingWithSitefrom
          :data="managers"
          :blockTitle="$t('company_menegers')"
          buttonLink="/company/working-with-the-site/company-managers/result?page=1"
        />
      </div>

      <!-- Skeleton -->
      <div class="workingWithSite__block" v-else>
        <div
          class="workingWithSite__item skeleton__block"
          v-for="el of 4"
          :key="el"
        >
          <div>
            <div class="title skeleton middle-text"></div>
            <div class="center">
              <div
                class="info"
                v-for="item of 3"
                :key="item"
              >
                <p class="subtitle skeleton small-text"></p>
                <p class="description skeleton small-text"></p>
              </div>
            </div>
          </div>
          <div class="btns">
            <Button class="skeleton" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import WorkingWithSitefrom from '@/components/uiCompany/WorkingWithSite.vue'

import moment from 'moment'
import { ref } from '@vue/reactivity'
import { onMounted } from 'vue-demi'
import { useI18n } from 'vue-i18n'

import { apiUrl } from '@/api'
import { formatToPhone } from '@/helpers/formatPhone'
import { getManagers, getResponses, getContract, getConnectedServices } from '@/actions/company'

const i18n = useI18n()

const filter = (
  {
    per_page: 3
  }
)

const contract = ref([])
const contractPdf = ref(null)

const connectedServices = ref([])
const responses = ref([])
const managers = ref([])

const generateFullName = data => {
  return `${data.name} ${data.surname.slice(0, 1)}${data.patronym ? '.' + data.patronym.slice(0, 1) : ''}`
}

const fetchData = async () => {
  // ====== Contract =======
  const responseContract = (await getContract()).data.contract
  if (responseContract.doc_file) {
    contract.value = [
      {
        title: i18n.t('service_agreement')
      },
      {
        title: `${i18n.t('signed')}: ${moment(responseContract.signed).format('DD-MM-YYYY')}`
      },
      {
        title: `${i18n.t('deadline_up_to')} ${moment(responseContract.limit).format('DD-MM-YYYY')}`
      }
    ]
    contractPdf.value = responseContract.doc_file
  } else {
    contract.value = [
      {
        title: i18n.t('there_is_no_contract')
      }
    ]
  }

  // Connected Services
  const responseConnectedServices = (await getConnectedServices()).data
  responseConnectedServices.forEach(service => {
    if (service.id === 4) {
      connectedServices.value.push(
        {
          title: `${i18n.t('LastMinuteJobs')}(${service.package.vacancies_count})`,
          info: moment(service.created_at).format('DD.MM.YYYY')
        }
      )
    }
  })

  let managersAll = 0
  let vacanciesAll = 0

  let availableManagers = 0
  let availableVacancies = 0

  responseConnectedServices.forEach(service => {
    if (service.id !== 4) {
      if (service.package.managers_count) managersAll += service.package.managers_count
      if (service.package.vacancies_count) vacanciesAll += service.package.vacancies_count

      if (service.available_managers_count) availableManagers += service.available_managers_count
      if (service.available_vacancies_count) availableVacancies += service.available_vacancies_count
    }
  })

  connectedServices.value.push(
    {
      title: `${i18n.t('vacancy_placement')}(${vacanciesAll})`,
      info: `${i18n.t('left')}: ${availableVacancies}`
    },
    {
      title: `${i18n.t('adding_a_manager')}(${managersAll})`,
      info: `${i18n.t('left')}: ${availableManagers}`
    }
  )

  // ====== Responses =======
  const responseResponces = (await getResponses(filter)).data.responds.data
  if (responseResponces.length !== 0) {
    responseResponces.forEach(response => {
      responses.value.push(
        {
          title: generateFullName(response.resume.user_employee),
          info: moment(response.created_at).format('DD.MM.YYYY')
        }
      )
    })
  } else {
    managers.value.push(
      {
        title: i18n.t('message.looks_like_it_s_empty')
      }
    )
  }

  // ====== Managers =======
  const responseManagers = (await getManagers(filter)).data.managers.data
  responseManagers.forEach(manager => {
    managers.value.push(
      {
        title: generateFullName(manager),
        info: formatToPhone(manager.phone),
        link: manager.phone
      }
    )
  })
}

onMounted(
  async () => {
    fetchData()
  }
)
</script>

<style lang="scss" scoped>
.workingWithSite{
  &__block{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }
}
@media (max-width: 768px){
  .workingWithSite{
    &__block{
      grid-template-columns: 1fr;
    }
  }
}
@media (max-width: 395px){
  .workingWithSite{
    &__block{
      grid-template-columns: 1fr;
    }
  }
}

.skeleton__block{
  & .title{
    width: 300px;
    max-width: 100%;
  }
  & .info{
    margin-bottom: 6px;
  }
  & .subtitle{
    width: 160px;
    max-width: 100%;
  }
  & .button{
    width: 140px;
  }
}
</style>
