<template>
  <Header />
  <section class="companyDetail">
      <div class="container">
        <div v-if="company">
          <div class="personalData__block">
            <div class="personalData__preview">
              <img v-if="company.logo" :src="`${apiUrl}/${company.logo}`" alt="">
              <img v-else src="/img/tmp/defold.png" alt="">
            </div>
            <div class="personalData__content">
              <div>
                <div class="personalData__name">{{company.name}}</div>
                <p>{{`${company.country.title}, ${company.city?.title}`}}</p>
                <p>{{`${$t('field_of_activity')}: ${company.industry?.title ?? $t('not_set')}`}}</p>
                <p>{{`${$t('phone')}: ${formatToPhone(company.phone)}`}}</p>
                <p>{{`E-mail: ${company.email}`}}</p>
              </div>

              <Button
                v-if="!showFeedback"
                :name="$t('i_want_to_work_here')"
                @click="showFeedback = true"
              />
            </div>
          </div>
          <Feedback
            v-if="showFeedback"
            v-model="formData"
            @hide="() => {showFeedback = false}"
          />

          <!-- Company Vcancies -->
          <div class="searchVacanciesClient searchVacnciesUser">
            <ListVacancies
              v-if="vacancies.length !== 0"
              :vacancies="vacancies"
            />
          </div>

        </div>
        <div class="personalData__block" v-else>
          <div class="editPersonalData__preview">
            <div class="img skeleton skeleton-img"></div>
          </div>
          <div class="personalData__content">
            <div style="width:100%;">
              <div class="personalData__name skeleton middle-text" style="width:100%;max-width: 500px;"></div>
              <div style="display:flex;margin-bottom:15px;grid-gap:10px" v-for="item of 7" :key="item"><span class="skeleton middle-text" style="width: 70px"></span><span class="skeleton middle-text"></span></div>
            </div>

            <Button class="skeleton" style="width:200px; pointer-events: none"/>
          </div>
        </div>
      </div>
  </section>
</template>

<script setup>
import ListVacancies from '@/components/uiEmployee/Companies/Detail/ListVacancies.vue'
import Feedback from '@/components/uiEmployee/FeedbackForm.vue'

import { apiUrl } from '@/api'
import { ref } from '@vue/reactivity'
import { useRoute } from 'vue-router'
import { onMounted } from 'vue-demi'

import { formatToPhone } from '@/helpers/formatPhone'
import { getCompanyDetail } from '@/actions/employee'

const route = useRoute()
const company = ref(null)
const vacancies = ref([])
const formData = ref({})
const showFeedback = ref(false)
onMounted(
  async () => {
    const responce = (await getCompanyDetail(route.params.company_id)).data
    company.value = responce.company
    vacancies.value = responce.vacancies
    formData.value.company_id = company.value.id
  }
)
</script>

<style lang="scss">
.companyDetail{
  & .personalData__block{
    margin-bottom: 100px;
  }

  @media (max-width: 768px){
  & .personalData{
      &__block{
        grid-template-columns: 240px 1fr;
        grid-gap: 20px;
        margin-bottom: 60px;
      }
    }
  }

  @media (max-width: 576px){
  & .personalData{
      &__block{
        grid-template-columns: 1fr;
        grid-gap: 30px;
        margin-bottom: 40px;
      }
      &__preview{
        width: 180px;
        height: 180px;
      }
      &__content > div:first-child{
        margin-bottom: 20px;
      }
    }
  }
}
</style>
