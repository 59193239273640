<template>
  <Header fromMain/>
  <section class="intro section">
      <div class="left">
      </div>
      <div class="intro__content">
          <div class="container">
              <div class="left-content">
                  <Search />
                  <h2 class="intro__title">
                    <span>{{$t('intro.subtitle')}}</span>
                  </h2>
                  <p class="intro__subtitle">{{$t('intro.title')}}</p>
              </div>
          </div>
      </div>
      <div class="right">
          <div class="container">
            <Statistics/>
          </div>
      </div>
  </section>

  <Partners />

  <Stock />

  <HotVacancies />

  <section class="aboutIndex">
      <div class="container">
          <div class="aboutIndex__inner">
              <h3 class="section__title">{{$t('aboutUs.title')}}</h3>
              <div>
                <p>{{$t('aboutUs.text.text1')}}</p>
                <p>{{$t('aboutUs.text.text2')}}</p>
                <p>{{$t('aboutUs.text.text3')}}</p>
                <p>{{$t('aboutUs.text.text4')}}</p>
                <p>{{$t('aboutUs.text.text5')}}</p>
                <p>{{$t('aboutUs.text.text6')}}</p>
              </div>
          </div>
      </div>
  </section>

  <Services />

  <News homePage/>
</template>

<script setup>
import Search from '@/components/common/Search.vue'
import Statistics from '@/components/uiLanding/HomePage/Statistics.vue'
import Partners from '@/components/uiLanding/HomePage/Partners.vue'
import Stock from '@/components/uiLanding/HomePage/Stock.vue'
import Services from '@/components/uiLanding/HomePage/Services.vue'
import News from '@/components/uiLanding/HomePage/NewsList.vue'
import HotVacancies from '@/components/uiLanding/HomePage/HotVacancies.vue'

import { onMounted } from 'vue-demi'
import Cookies from 'js-cookie'
import { useAuth } from '@/modules/auth.js'

onMounted(
  () => {
    if (Cookies.get('auth_data') && localStorage.getItem('auth_data') !== Cookies.get('auth_data')) {
      const { setUser } = useAuth()
      localStorage.setItem('auth_data', Cookies.get('auth_data'))
      setUser({ token: Cookies.get('auth_data'), role_id: 3 }, null)
    }
  }
)
</script>

<style lang="scss">
.sale .sale__inner,
.hotVacancies .slider__inner,
.aboutIndex .aboutIndex__inner,
.services .services__inner{
  border-bottom: 1px solid rgba(0, 0, 0, 0.0955529);
  padding-bottom: 110px;
  @media (max-width: 992px){
    padding-bottom: 80px;
  }
  @media (max-width: 768px){
    padding-bottom: 70px;
  }
}
.intro{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 700px;
    max-height: 900px;
    position: relative;
    background: #999 url("/img/tmp/intro.jpg") center no-repeat;
    -webkit-background-size: cover;
    background-size: cover;

    position: relative;

    & .container{
      border-bottom: none !important;
    }

    & .left-content .container,
    & .right .container{
      padding-top: 0;
      padding-bottom: 0;
      border-bottom: none;
    }

    & .left{
      width: 67%;
      background-color: rgba(#212529, .5);
    }

    & .intro__content{
      position: absolute;
      height: 100%;
      width: 100%;
      & .container{
        padding-top: 110px !important;
      }
    }

    & .left-content{
        width: 66%;
        & .search{
          margin-bottom: 34px !important;
        }
    }

    &__title{
        color: #fff;
        font-size: 60px;
        font-weight: 800;
        line-height: 1.16;
        margin-bottom: 36px;
    }

    &__subtitle{
        font-size: 16px;
        color: #fff;
    }

    & .right{
        width: 33%;
        height: 100%;

        position: relative;
        padding-top: 176px;
        padding-left: 50px;

        & .container{
            height: 100%;
        }

        &::before{
            content: "";
            display: block;
            width: 100%;
            height: 100%;

            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            background: #AD1717;
            mix-blend-mode: darken;
        }
    }
}
.aboutIndex{
  & p{
    text-align: justify;
    margin-bottom: 35px;
  }
  & p:last-child{
    margin-bottom: 0;
  }
}
@media (max-width: 1005px){
  .index section .container{
    padding-top: 80px;
  }
}
@media (max-width: 910px){
  .intro{
    min-height: 600px;
    &__title,
    & .stat__count{
      font-size: 40px;
    }

    &__title{
      margin-bottom: 25px;
    }

    & .right{
      padding-left: 10px;
      min-height: 100%;
    }
  }
}
@media (max-width: 768px){
  .index section .container{
    padding-top: 70px;
  }
  .aboutIndex p{
    margin-bottom: 18px;
  }
}
@media (max-width: 630px){
  .intro{
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0;

    &__title,
    &__subtitle{
        text-align: center;
    }

    & .left{
      position: absolute;
      width: 100%;
      height: 100%;
    }

    & .left-content{
      width: auto;
    }

    &__content{
      position: relative !important;
      z-index: 1;
      height: auto;
    }

    & .right{
      width: 100%;
      min-height: auto;
      height: auto;
      padding-left: 0;
      padding-top: 20px;
      & .container{
        padding-bottom: 20px;
      }
    }
    & .stat{
      display: flex;
      justify-content: space-around;

      &__item{
        margin-bottom: 0;
      }
    }
  }
}
@media (max-width: 576px){
  .aboutIndex p{
    margin-bottom: 12px;
  }
}
@media (max-width: 415px){
  .intro{
    &__title{
      font-size: 30px;
      margin-bottom: 20px;
    }
    & .stat{
      justify-content: space-between;
      &__count{
        font-size: 25px;
      }
    }
  }
}
</style>
